/*
 * Author: Andries van Weeren
 * E-mail: a.vanweeren@fcroc.nl
 * Project: fcsource4
 * Created: 02-10-20 13:19
 * Filename: main
 */
require('./main.scss');
const $ = require('jquery');
import 'jquery-ui';
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';
import './fonts.css';

// This will extend the $.fn prototype with Filterizr
// Filterizr.installAsJQueryPlugin($);
